<template>
  <v-container>
    <loading
      :active="isloadagents"
      :is-full-page="false"
      :opacity="0.7"
      color="primary"
    />
    <loading
      :active="isload"
      :is-full-page="false"
      :opacity="0.7"
      color="primary"
    />
    <v-row class="mt-3">
      <v-col
        cols="12"
        v-if="isTrans == 'true'"
        class="d-flex w-100 justify-content-end"
      >
        <v-btn color="primary" @click="onClickCancel">
          {{ $t("cancel_beneficary") }}</v-btn
        >
      </v-col>
      <v-col cols="12" md="6">
        <label>{{ $t("country") }}<span style="color: #f00">*</span></label>
        <v-autocomplete
          v-model="beneficiaryReqBody.country_name"
          :placeholder="$t('country')"
          :items="country_options"
          :error-messages="benificiaryCountryErrors"
          @input="$v.beneficiaryReqBody.country_name.$touch()"
          required
          outlined
          dense
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <label
          >{{ $t("paymentMethod") }}<span style="color: #f00">*</span></label
        >
        <v-select
          v-model="payment_method_selected"
          :placeholder="$t('paymentMethod')"
          :menu-props="{ bottom: true, offsetY: true }"
          :items="method_options"
          item-text="txt"
          item-value="val"
          :error-messages="paymentMethodErrors"
          @input="$v.payment_method_selected.$touch()"
          :disabled="ispaymodedisable"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" v-if="payment_method_selected === 'B'">
        <label>{{ $t("bankName") }}<span style="color: #f00">*</span></label>
        <v-autocomplete
          :placeholder="$t('bankName')"
          v-model="beneficiaryReqBody.bank_name"
          :items="bank_names"
          :error-messages="bankNameErrors"
          @input="$v.beneficiaryReqBody.bank_name.$touch()"
          required
          outlined
          dense
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6" v-if="payment_method_selected === 'B'">
        <label
          >{{ $t("branch_code_personal")
          }}<span
            style="color: #f00"
            v-if="
              this.beneficiaryReqBody.country_name === 'India' ||
              beneficiaryReqBody.country_name === 'Bangladesh'
            "
            >*</span
          >
          <br v-if="webbreakdown()" />
          <span v-if="this.beneficiaryReqBody.country_name === 'India'"
            >( ifsc code:
            <a href="https://www.ifsccodebank.com/" target="_blank"
              >www.ifsccodebank.com</a
            >
            )</span
          ></label
        >
        <v-text-field
          :placeholder="$t('branch_code_personal')"
          v-model="beneficiaryReqBody.branch_code"
          :error-messages="
            (payment_method_selected === 'B' &&
              beneficiaryReqBody.country_name === 'India') ||
            beneficiaryReqBody.country_name === 'Bangladesh'
              ? branchCodeErrors
              : newBranchCodeErrors
          "
          @input="$v.beneficiaryReqBody.branch_code.$touch()"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="payment_method_selected != 'B' && payment_method_selected != 'A'"
      >
        <label
          >{{ $t("payoutLocation") }}<span style="color: #f00">*</span></label
        >
        <v-autocomplete
          :placeholder="$t('payoutLocation')"
          v-model="beneficiary_payment_location"
          :items="payment_location"
          item-text="txt"
          item-value="val"
          :error-messages="payoutLocationErrors"
          @input="$v.beneficiary_payment_location.$touch()"
          required
          outlined
          dense
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="
          payment_method_selected === 'B' || payment_method_selected === 'W'
        "
      >
        <label
          >{{ $t("account_number_personal")
          }}<span style="color: #f00">*</span></label
        >
        <v-text-field
          :placeholder="$t('account_number_personal')"
          v-model="beneficiaryReqBody.bank_account_no"
          :error-messages="accountNoErrors"
          @input="$v.beneficiaryReqBody.bank_account_no.$touch()"
          required
          outlined
          dense
        >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="
          payment_method_selected === 'A' &&
          beneficiaryReqBody.country_name === 'Indonesia'
        "
      >
        <label
          >{{ $t("Policy_Number") }}<span style="color: #f00">*</span></label
        >
        <v-text-field
          :placeholder="$t('Policy_Number')"
          v-model="beneficiaryReqBody.policy_number"
          :error-messages="policyNoErrors"
          @input="$v.beneficiaryReqBody.policy_number.$touch()"
          required
          outlined
          dense
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" md="12">
        <v-btn
          class="float-left mt-5 px-5 btn-primary-outlined text-capitalize"
          @click="$emit('onClickBack')"
          outlined
        >
          <v-icon dark left>mdi-arrow-left</v-icon>
          {{ $t("backLabel") }}
        </v-btn>
        <v-btn
          class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
          @click="onClickNext"
          outlined
        >
          {{ $t("next") }} <v-icon dark right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import {
  required,
  requiredIf,
  // numeric,
  alphaNum,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
// import axios from "axios";
// import { alpha } from "../../../utils/validate";
import { webbreakdown } from "../../../utils/breakpoints";
import alerts from "@/mixins/alerts";
import commonService from "@/services/CommonService/commonService";

export default {
  name: "AccountType",
  mixins: [alerts],
  props: {
    beneficiaryReqBody: {
      type: Object,
      required: true,
    },
    isTrans: {
      default: "",
      type: String,
    },
    getrecieverDetails: {
      type: Object,
      required: true,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    createdBeneficiary: {
      type: Boolean,
    },
  },
  data() {
    return {
      webbreakdown,

      country_options: [],
      recieverDetails: {},
      country_obj: [],
      countryISO3: "IND",
      method_options: [],
      payment_location: [],
      payment_method_selected: {},
      bank_names: [],
      payment_location_name: "",
      payment_location_id: "",
      pay_crncy_cd: "INR",
      paying_cntry_cd: "IND",
      payment_mode_cd: "B",
      paying_cntry_name: "India",
      beneficiary_payment_location: {},
      selctedpaymentMode: "",
      ispaymodedisable: false,
      isloadagents: false,
      isload: false,
    };
  },
  components: {
    Loading,
  },
  validations() {
    return {
      payment_method_selected: {
        required,
      },
      beneficiary_payment_location: {
        required: requiredIf(function () {
          if (
            this.payment_method_selected != "B" &&
            this.payment_method_selected != "A"
          )
            return true;
          else return false;
        }),
      },
      beneficiaryReqBody: {
        country_name: {
          required,
        },
        bank_name: {
          required: requiredIf(function () {
            if (this.payment_method_selected === "B") return true;
            else return false;
          }),
          // alpha,
        },
        bank_account_no: {
          required: requiredIf(function () {
            if (
              this.payment_method_selected === "B" ||
              this.payment_method_selected === "W"
            )
              return true;
            else return false;
          }),
          alphaNum,
        },
        policy_number: {
          required: requiredIf(function () {
            if (
              this.payment_method_selected === "A" &&
              this.beneficiaryReqBody.country_name === "Indonesia"
            )
              return true;
            else return false;
          }),
          alphaNum,
        },
        branch_code: {
          minLength:
            this.payment_method_selected === "B" &&
            this.beneficiaryReqBody.country_name === "India"
              ? minLength(11)
              : false,
          maxLength:
            this.payment_method_selected === "B" &&
            this.beneficiaryReqBody.country_name === "India"
              ? maxLength(11)
              : false,
          required: requiredIf(function () {
            if (
              this.payment_method_selected === "B" &&
              this.beneficiaryReqBody.country_name === "India"
            ) {
              return true;
            } else if (
              this.payment_method_selected === "B" &&
              this.beneficiaryReqBody.country_name === "Bangladesh"
            ) {
              return true;
            } else {
              return false;
            }
          }),
          alphaNum,
        },
      },
    };
  },
  computed: {
    ...mapGetters("Authentication", ["country_list"]),
    ...mapGetters("Transactional", ["realtion_list"]),
    benificiaryCountryErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.country_name.$dirty) return errors;
      !this.$v.beneficiaryReqBody.country_name.required &&
        errors.push(this.$t("select_beneficiary"));
      return errors;
    },
    paymentMethodErrors() {
      const errors = [];
      if (!this.$v.payment_method_selected.$dirty) return errors;
      !this.$v.payment_method_selected.required &&
        errors.push(this.$t("select_payment_method"));
      return errors;
    },
    bankNameErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.bank_name.$dirty) return errors;
      if (!this.$v.beneficiaryReqBody.bank_name.required) {
        errors.push(this.$t("select_bank_name"));
      }
      return errors;
    },
    accountNoErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.bank_account_no.$dirty) return errors;
      if (!this.$v.beneficiaryReqBody.bank_account_no.required) {
        errors.push(this.$t("account_number_valid"));
      }
      if (!this.$v.beneficiaryReqBody.bank_account_no.alphaNum) {
        errors.push(this.$t("account_number_valid"));
      }

      return errors;
    },
    policyNoErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.policy_number.$dirty) return errors;
      if (!this.$v.beneficiaryReqBody.policy_number.required) {
        errors.push(this.$t("policy_numb_req"));
      }
      if (!this.$v.beneficiaryReqBody.policy_number.alphaNum) {
        errors.push(this.$t("policy_numb_invalid"));
      }

      return errors;
    },
    branchCodeErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.branch_code.$dirty) return errors;
      !this.$v.beneficiaryReqBody.branch_code.required &&
        errors.push(this.$t("ifsc_code"));
      !this.$v.beneficiaryReqBody.branch_code.alphaNum &&
        errors.push(this.$t("invalid_ifsc_code"));
      !this.$v.beneficiaryReqBody.branch_code.minLength &&
        errors.push(this.$t("branch_code_minlength"));
      !this.$v.beneficiaryReqBody.branch_code.maxLength &&
        errors.push(this.$t("branch_code_minlength"));
      return errors;
    },
    newBranchCodeErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.branch_code.$dirty) return errors;
      !this.$v.beneficiaryReqBody.branch_code.required &&
        errors.push(this.$t("ifsc_code"));
      !this.$v.beneficiaryReqBody.branch_code.alphaNum &&
        errors.push(this.$t("invalid_ifsc_code"));
      return errors;
    },
    payoutLocationErrors() {
      const errors = [];
      if (!this.$v.beneficiary_payment_location.$dirty) return errors;
      !this.$v.beneficiary_payment_location.required &&
        errors.push(this.$t("select_payout_location"));
      return errors;
    },
  },
  watch: {
    getrecieverDetails: {
      handler(newVal) {
        this.recieverDetails = newVal;
        this.beneficiaryReqBody.policy_number =
          this.recieverDetails.policy_number;
        this.beneficiaryReqBody.branch_code = this.recieverDetails.branch_code;
        this.beneficiaryReqBody.bank_account_no =
          this.recieverDetails.bank_account_no;
        console.log("new", this.recieverDetails);
      },
    },
    "$store.state.selctedPayMode": function () {
      this.payment_method_selected = this.$store.state.selctedPayMode;
      this.ispaymodedisable = false;

      console.log("datat", this.$store.state.paymodeCountry.country);
      this.beneficiaryReqBody.country_name =
        this.$store.state.paymodeCountry.country;
    },
    "$store.state.paymodeCountry.country.name": function () {
      console.log("datat", this.$store.state.paymodeCountry.country);
      this.beneficiaryReqBody.country_name =
        this.$store.state.paymodeCountry.country;
    },
    selctedpaymentMode(newval) {
      console.log("PayMode :", newval);
    },

    async "beneficiaryReqBody.country_name"(val) {
      this.paying_cntry_name = val;
      console.log("country name", val);
      this.country_obj.forEach((obj) => {
        console.log(obj.country_name);
        if (obj.country_name === val) {
          this.countryISO3 = obj.iso_alpha3;
          this.pay_crncy_cd = obj.currency_code;
          this.paying_cntry_cd = obj.iso_alpha3;
          this.getPaymentMode(obj.iso_alpha3);
        }
      });
      console.log("country chnaged:", this.payment_method_selected.length);
      if (this.payment_method_selected.length) {
        await this.getpayLocation(
          this.payment_method_selected,
          this.countryISO3
        );
      } else if (!this.$route.query.Remittee_ID) {
        this.getpayLocation("B", this.countryISO3);
      }
    },
    payment_method_selected(newVal) {
      // 1.call payout location (Post&Put)
      // 2.map payout mode
      this.payment_mode_cd = newVal;
      console.log("payment_method_selected ", newVal);
      this.method_options.forEach((obj) => {
        if (obj.val === newVal) {
          this.getpayLocation(obj.val, this.countryISO3);
          this.beneficiaryReqBody.payment_mode_name = obj.txt;
        }
      });
      console.log("this.method_options", this.method_options);

      if (newVal) this.beneficiaryReqBody.payment_mode = newVal;
    },
    beneficiary_payment_location(val) {
      this.payment_location_name = val.locationName;
      this.payment_location_id = val.locationId;
    },
    "beneficiaryReqBody.bank_name"(val) {
      console.log("beneficiaryReqBody.bank_nam", val);
      this.payment_location.map((i) => {
        if (i.txt === val) {
          this.payment_location_name = i.txt;
          this.payment_location_id = i.val.locationId;
        }
      });
      console.log("payment_location", this.payment_location);
    },
  },
  mounted() {
    //to map payment mode comes from send money page
    if (this.$store.state.showDialog) {
      this.ispaymodedisable = true;
      this.payment_method_selected = this.$store.state.selctedPayMode;
    } else {
      this.ispaymodedisable = false;
    }
    this.getCountryList();
    if (!this.$route.query.Remittee_ID) {
      this.beneficiaryReqBody.country_name = "India";
      // this.getPaymentMode("IND");
      // this.getpayLocation("B", this.countryISO3);
    }
  },
  created() {},
  methods: {
    onClickCancel() {
      this.$router.push("/transaction/3");
    },
    onClickBack() {
      this.$emit("onClickBack");
    },
    onClickNext() {
      console.log("pay", this.$v);
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (!this.$route.query.Remittee_ID) {
        this.beneficiaryReqBody.payment_location =
          this.beneficiary_payment_location?.locationName;
      } else {
        this.beneficiaryReqBody.payment_location =
          this.beneficiary_payment_location?.val?.locationName;
      }

      console.log(
        "ddsds",
        this.beneficiaryReqBody,
        this.payment_method_selected,
        this.method_options.find((e) => e.val === this.payment_method_selected)
      );
      if (
        this.payment_method_selected === "A" &&
        this.beneficiaryReqBody.country_name === "Indonesia" &&
        this.beneficiaryReqBody.policy_number
      ) {
        this.checkPolicyNumber();
      } else {
        this.$emit(
          "onClickNext",

          Object.assign(this.beneficiaryReqBody, {
            country_code: this.countryISO3,
            // country_id: this.beneficiaryReqBody.country_name.id,
            country_name: this.beneficiaryReqBody.country_name,
            // bank_name: this.beneficiaryReqBody.bank_name,
            branch_code: this.beneficiaryReqBody.branch_code,
            bank_account_no: this.beneficiaryReqBody.bank_account_no,
            location_id: this.payment_location_id,
            location_name: this.payment_location_name,
            currency_code: this.pay_crncy_cd,
            payment_code: this.payment_mode_cd,
            mobile_number: this.paying_cntry_cd,
            policy_number: this.beneficiaryReqBody.policy_number,
            payment_mode_name: this.beneficiaryReqBody.payment_mode_name,
          })
        );
      }
    },
    async checkPolicyNumber() {
      // const token = sessionStorage.getItem("access_token");
      this.isloadagents = true;
      try {
        const responseData = await commonService.checkPolicyNumber(
          this.beneficiaryReqBody.policy_number
        );
        // const responseData = await axios({
        //   url: `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/inquire-insurance?insurance_code=${this.beneficiaryReqBody.policy_number}&currency_code=IDR`,
        //   method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authorization: `Bearer ${token}`,
        //     accept: "application/json",
        //   },
        // });
        console.log("get check data:", responseData.data.data[0]);
        if (responseData.data.status_code == 200) {
          this.isloadagents = false;
          this.$emit(
            "onClickNext",

            Object.assign(this.beneficiaryReqBody, {
              country_code: this.countryISO3,
              // country_id: this.beneficiaryReqBody.country_name.id,
              country_name: this.beneficiaryReqBody.country_name,
              // bank_name: this.beneficiaryReqBody.bank_name,
              branch_code: this.beneficiaryReqBody.branch_code,
              bank_account_no: this.beneficiaryReqBody.bank_account_no,
              location_id: this.payment_location_id,
              location_name: this.payment_location_name,
              currency_code: this.pay_crncy_cd,
              payment_code: this.payment_mode_cd,
              mobile_number: this.paying_cntry_cd,
              policy_number: this.beneficiaryReqBody.policy_number,
              payment_mode_name: this.beneficiaryReqBody.payment_mode_name,
              first_name: responseData.data.data[0].name,
            })
          );
          console.log(this.beneficiaryReqBody, "BBBB");
          console.log("check Data:", responseData);
        } else {
          this.isloadagents = false;
          console.log("check error", responseData);
        }
      } catch (err) {
        this.isloadagents = false;
        console.error(err);
        return;
      }
    },
    async getCountryList() {
      try {
        let responseData = await commonService.getBeneficiaryCountryList();
        if (responseData && responseData.data.status_code == 200) {
          responseData.data.data.map((i) => {
            this.country_options.push(i.country_name);
            this.country_obj.push(i);
          });
          // mapping values while update Beneficiary
          console.log("country_obj", this.country_obj);
          if (this.recieverDetails.country_name) {
            this.beneficiaryReqBody.country_name =
              this.recieverDetails.country_name;
          }
          // if page mounted and payment mode is hitted with IND code
          if (!this.$route.query.Remittee_ID) {
            this.getPaymentMode("IND");
          }
        }
      } catch (error) {
        console.log(error);
        // this.loader = false;
        // this.showAlertError("Error: Something went wrong.Please try again later.");
      }
    },

    async getPaymentMode(country_iso3) {
      // const token = sessionStorage.getItem("access_token");
      this.isload = true;
      try {
        const responseData = await commonService.getPaymentmodeByCountry(
          country_iso3
        );

        console.log(responseData.data);
        if (responseData.status == 200) {
          this.method_options = await responseData.data.data.map((i) => {
            return {
              txt: i.value,
              val: i.value_code,
            };
          });
          // mapping values while update Beneficiary
          setTimeout(() => {
            if (
              Array.isArray(this.method_options) &&
              this.method_options.length > 0 &&
              this.recieverDetails.payment_code.payment_mode_code
            ) {
              this.payment_method_selected =
                this.recieverDetails.payment_code.payment_mode_code;
            }
          }, 300);
          if (!this.$route.query.Remittee_ID) {
            // while page is mounted bank transfer is mapped
            //and watch function trigger payout location API
            this.method_options.map((e) => {
              if (e.data != "B") {
                this.payment_method_selected = "B";
              }
            });
          }
          this.isload = false;
        } else {
          this.isload = false;
          this.method_options = [];
          return;
        }
      } catch (err) {
        this.isload = false;
        console.error(err);
        this.method_options = [];
        return;
      }
    },
    async getpayLocation(payment_mode, selected_country) {
      // const token = sessionStorage.getItem("access_token");
      this.isloadagents = true;
      try {
        const responseData = await commonService.getPayLocation(
          selected_country,
          payment_mode
        );
        // const responseData = await axios({
        //   url: `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-bank-list?country=${selected_country}&payment_mode=${payment_mode}`,
        //   method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authorization: `Bearer ${token}`,
        //     accept: "application/json",
        //   },
        // });
        console.log("getpayLocation:", responseData);
        if (responseData.status == 200) {
          this.payment_location = await responseData.data.data.map((i) => {
            return {
              txt: i.locationName,
              val: { locationId: i.locationId, locationName: i.locationName },
            };
          });
          console.log("this.payment_location", this.payment_location);
          this.bank_names = await responseData.data.data.map(
            (i) => i.locationName
          );

          // to place the values inside textbox on edit selected
          setTimeout(() => {
            if (
              Array.isArray(this.payment_location) &&
              this.payment_location.length > 0
            )
              this.payment_location.map((i) => {
                console.log(this.recieverDetails.location_name, "loc name");
                if (i.txt === this.recieverDetails.location_name) {
                  if (
                    this.recieverDetails &&
                    this.recieverDetails.payment_code &&
                    this.recieverDetails.payment_code.payment_mode_code == "B"
                  ) {
                    this.beneficiaryReqBody.bank_name = i.val.locationName;
                  } else {
                    this.beneficiary_payment_location = {
                      locationId: i.val.locationId,
                      locationName: i.val.locationName,
                    };
                    // this.beneficiary_payment_location = {
                    //   txt: i.txt,
                    //   val: {
                    //     locationId: i.val.locationId,
                    //     locationName: i.val.locationName,
                    //   },
                    // };
                  }
                }
              });
            this.isloadagents = false;
          }, 250);
          this.isloadagents = false;
          console.log(this.beneficiaryReqBody.payment_mode);
        } else {
          this.isloadagents = false;
          this.payment_location = [];
          this.bank_names = [];
          return;
        }
      } catch (err) {
        this.isloadagents = false;
        console.error(err);
        this.payment_location = [];
        this.bank_names = [];
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";
</style>
