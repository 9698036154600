<template>
  <div class="page-body">
    <TitleBar :title="$t('addBeneficiary')" />
    <v-container>
      <v-alert
        v-if="alertMsg.status"
        :timeout="alertMsg.timeout"
        :color="alertMsg.color"
        :type="alertMsg.type"
        dismissible
      >
        {{ alertMsg.text }}
      </v-alert>
      <v-row class="d-flex justify-content-center ben_main" no-gutters>
        <v-col col sm="12" md="12" lg="12">
          <v-stepper v-model="currentStep">
            <v-stepper-header>
              <v-stepper-step :complete="currentStep > 1" step="1">{{
                $t("accountType")
              }}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="currentStep > 2" step="2">{{
                $t("bankDetails")
              }}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="currentStep > 3" step="3">{{
                $t("basicDetails")
              }}</v-stepper-step>

              <v-divider></v-divider>
              <v-stepper-step :complete="currentStep > 4" step="4">{{
                $t("summary")
              }}</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <benificiary-type
                  :isTrans="isTrans"
                  :beneficiaryReqBody="beneficiaryReqBody"
                  :getrecieverDetails="getrecieverDetails"
                  @onClickNext="onSelectAccountType"
                />
              </v-stepper-content>
              <v-stepper-content step="2">
                <account-type
                  :isTrans="isTrans"
                  :beneficiaryReqBody="beneficiaryReqBody"
                  :bene_country_name="bene_country_name"
                  :getrecieverDetails="getrecieverDetails"
                  @onClickNext="onClickNext"
                  @onClickBack="onClickBack"
                />
              </v-stepper-content>
              <v-stepper-content step="3">
                <basic-details
                  :isTrans="isTrans"
                  :beneficiaryReqBody="beneficiaryReqBody"
                  :bene_country="bene_country_name"
                  :getrecieverDetails="getrecieverDetails"
                  :payment_method_selected="payment_method_selected"
                  @onClickNext="onClickNext"
                  @onClickBack="onClickBack"
                />
              </v-stepper-content>

              <v-stepper-content step="4">
                <Summary
                  :isTrans="isTrans"
                  :beneficiaryReqBody="beneficiaryReqBody"
                  :loader="loader"
                  @onClickNext="onCreateBeneficiary"
                  @onClickBack="onClickBack"
                ></Summary>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TitleBar from "@/components/navigations/TitleBar.vue";
import BenificiaryType from "@/components/BeneficiaryPage/BenificiaryType/index.vue";
import AccountType from "@/components/BeneficiaryPage/AccountType/index.vue";
import BasicDetails from "@/components/BeneficiaryPage/BasicDetails/index.vue";
// import AddressSection from "@/components/BeneficiaryPage/AddressSection/index.vue";
import Summary from "@/components/BeneficiaryPage/Summary/index.vue";
import remitteService from "@/services/RemitteService/remitteService";
import alerts from "@/mixins/alerts";
// import axios from "axios";
import { EventBus } from "../../plugins/events.js";
export default {
  name: "Beneficiary",
  mixins: [alerts],
  components: {
    TitleBar,
    BenificiaryType,
    AccountType,
    BasicDetails,
    // AddressSection,
    Summary,
  },
  data() {
    return {
      isTrans: "",
      steps: [
        { text: this.$t("accountType") },
        { text: this.$t("basicDetails") },
        // { text: this.$t("address") },
        { text: this.$t("bankDetails") },
      ],
      currentStep: 1,
      loader: false,
      beneficiaryReqBody: {
        payment_mode_name: "",
        rec_type: "IND",
        country_name: "",
        country_code: "",
        currency_code: "",
        payment_code: "",
        branch_code: "",
        branch_id: 0,
        bank_id: 0,
        bank_name: "",
        bank_account_no: null,
        location_id: "",
        location_name: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        last_name2: "",
        relationship_code: "",
        relationship_name: "",
        phone_number: "",
        mobile_number: "",
        address: "",
        state_code: "",
        state_name: "",
        district_code: "",
        city_code: "",
        city_name: "",
        postal_code: "",
        policy_number: "",
        email_id: null,
        id_no: "",
        id_type: "",
      },
      getrecieverDetails: {},
      bene_country_name: "",
      payment_method_selected: "",
      alertMsg: {
        status: false,
        text: "",
        timeout: 2000,
        color: "blue-grey",
        type: "info",
      },
    };
  },
  watch: {},
  created() {
    if (this.$route.query.Remittee_ID)
      this.getRemitteDetailByID(this.$route.query.Remittee_ID);
  },
  mounted() {
    this.$emit("getProfile");
    this.isTrans = this.$route.params?.isTran || "";

    console.log("remitteId ", this.$route);
    // This will work in edit mode.
  },
  methods: {
    onClickNext() {
      this.currentStep++;
      console.log("this.beneficiaryReqBody", this.beneficiaryReqBody);
      this.bene_country_name = this.beneficiaryReqBody.country_name;
      this.payment_method_selected = this.beneficiaryReqBody.payment_mode;
      if (
        this.beneficiaryReqBody.bank_account_no &&
        this.beneficiaryReqBody.payment_mode == "W"
      ) {
        this.beneficiaryReqBody.phone_number = this.beneficiaryReqBody
          .bank_account_no
          ? this.beneficiaryReqBody.bank_account_no
          : "";
      }
    },
    onClickBack() {
      this.currentStep--;
    },
    onSelectAccountType() {
      // console.log("beneficiaryReqBody", this.beneficiaryReqBody);
      if (this.beneficiaryReqBody.registration_type_cd == "BUS") {
        if (this.isTrans == "true") this.$router.push("/beneficiary-bus/true");
        else this.$router.push({ name: "BeneficiaryBus" });
      } else this.currentStep++;
    },
    async onCreateBeneficiary() {
      console.log(" this.beneficiaryReqBody", this.beneficiaryReqBody);
      EventBus.$emit("serviceCharge", {
        country_name: this.beneficiaryReqBody.paying_cntry_cd,
        paymentmode: this.beneficiaryReqBody.payment_mode_cd,
      });
      try {
        this.loader = true;
        let responseData;
        if (this.$route.query.Remittee_ID) {
          responseData = await remitteService.updateRemittee(
            Object.assign(this.beneficiaryReqBody, {
              remitteID: this.$route.query.Remittee_ID,
            })
          );
        } else {
          responseData = await remitteService.createRemittee(
            this.beneficiaryReqBody
          );
        }

        if (responseData && responseData.data.status_code == 200) {
          this.loader = false;
          this.showAlertSuccess(responseData.data.message);
          if (this.isTrans == "true") {
            this.$router.push("/transaction/3");
            this.$store.state.CreatedBeneDetails = this.beneficiaryReqBody;
            this.$root.$emit("component1");
          } else {
            setTimeout(() => {
              this.$router.push({ name: "BeneficiarySummary" });
            }, 1500);
          }
        } else {
          this.loader = false;
          this.showAlertError(responseData.data.message);
        }
      } catch (error) {
        this.loader = false;
        this.showAlertError(
          "Error: Something went wrong.Please try again later."
        );
      }
    },

    async getRemitteDetailByID(remitteeID) {
      this.loader = true;
      try {
        const responseData = await await remitteService.getRemitteDetailByID(
          remitteeID
        );
        if (responseData.data.status_code === 200) {
          this.loader = false;
          const remiteeResult = responseData.data.data[0];
          console.log("remiteeResult", remiteeResult);
          this.getrecieverDetails = remiteeResult;
        } else {
          this.loader = false;
        }
      } catch (e) {
        this.loader = false;
        return e;
      }
    },
    // async getRemitteDetailByID(remitteeID) {
    //   const token = sessionStorage.getItem("access_token");
    //   try {
    //     const responseData = await axios.get(
    //       `${process.env.VUE_APP_REMITTE_USER_SERVICE}/api/v1/bene/get-receiver-by-id/${remitteeID}`,
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: `Bearer ${token}`,
    //           accept: "application/json",
    //         },
    //       }
    //     );
    //     console.log("getRemitteDetailByID: ", responseData.data);
    //     if (responseData.data.status_code === 200) {
    // const remiteeResult = responseData.data.data[0];
    // console.log("remiteeResult", remiteeResult);
    // this.getrecieverDetails = remiteeResult;
    //       // Object.assign(this.beneficiaryReqBody, {
    //       //   rec_type: "IND",
    //       //   country_name: remiteeResult.country_name,
    //       //   country_code: remiteeResult.country_code,
    //       //   currency_code: remiteeResult.currency_code,
    //       //   payment_code: remiteeResult.payment_code,
    //       //   branch_code: remiteeResult.branch_code,
    //       //   branch_id: remiteeResult.branch_id,
    //       //   bank_id: remiteeResult.bank_id,
    //       //   bank_name: remiteeResult.bank_name,
    //       //   bank_account_no: remiteeResult.bank_account_no,
    //       //   location_id: remiteeResult.location_id,
    //       //   location_name: remiteeResult.location_name,
    //       //   first_name: remiteeResult.first_name,
    //       //   middle_name: remiteeResult.middle_name,
    //       //   last_name: remiteeResult.last_name,
    //       //   last_name2: remiteeResult.last_name2,
    //       //   relationship_code: remiteeResult.relationship_code,
    //       //   phone_number: remiteeResult.phone_number,
    //       //   mobile_number: remiteeResult.mobile_number,
    //       //   address: remiteeResult.address,
    //       //   state_code: remiteeResult.state_code,
    //       //   district_code: remiteeResult.district_code,
    //       //   city_code: remiteeResult.city_code,
    //       //   postal_code: remiteeResult.postal_code,
    //       // });
    //       // console.log("***///", this.beneficiaryReqBody)
    //     }
    //   } catch (e) {
    //     return e;
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";
::v-deep ul {
  list-style-type: none !important;
}
::v-deep h4 {
  color: $txt;
  text-align: center;
}
.btn-light {
  font-weight: bold;
  color: $dimgrey;
  background: $white;
  margin: 0 5px;
  &:not(:disabled) {
    &:not(.disabled).active {
      color: $secondary;
    }
    &:not(.disabled) {
      &:active {
        color: $secondary;
      }
    }
  }
  &:hover {
    border: 1px solid red !important;
    color: $dimgrey;
    background: $white;
  }
}
.card {
  border: none;
}
::v-deep label {
  color: $lightslategrey !important;
  margin-bottom: 0 !important;
}
::v-deep .btn-light {
  border: 1px solid $lightgrey !important;
}
.floating-placeholder-name {
  color: red;
  margin-top: -12px;
  font-size: 14px;
  text-align: -webkit-left;
}
.color-error {
  background-color: rgb(240, 50, 50);
}
</style>
