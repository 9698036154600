var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"w-75 m-auto account-main-div"},[(_vm.alertMsg.status)?_c('v-alert',{key:_vm.alertMsg.key,attrs:{"value":_vm.alertMsg.status,"id":"alert","timeout":_vm.alertMsg.timeout,"color":_vm.alertMsg.color,"type":_vm.alertMsg.type,"dismissible":""}},[_vm._v(" "+_vm._s(_vm.alertMsg.text)+" ")]):_vm._e(),(_vm.isTrans == 'true')?_c('v-col',{staticClass:"d-flex justify-content-end"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onClickCancel}},[_vm._v(" "+_vm._s(_vm.$t("cancel_beneficary"))+" ")])],1):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-card',{staticClass:"card-selector text-center rounded-xl m-4",class:_vm.isActive
          ? 'border-primary accoun account-selection'
          : 'border-light account-selection',attrs:{"elevation":"4","rounded":"xl","disabled":_vm.isactiveBus,"tile":""},on:{"click":function($event){return _vm.onClickPersonalAccount('personal')}}},[_c('div',{staticClass:"p-4 h-100",staticStyle:{"position":"relative"}},[_c('h6',{class:_vm.isActive
              ? 'text-primary-color font-weight-bold'
              : 'text-secondary font-weight-normal'},[_vm._v(" "+_vm._s(_vm.$t("personal"))+" ")]),_c('br'),_c('v-icon',{staticClass:"account-icons",style:(_vm.isActive
              ? 'border: 1px solid #ff6b00;'
              : 'border: 1px solid #6c757d;'),attrs:{"color":_vm.isActive ? 'primary' : 'grey',"large":""}},[_vm._v(" mdi-account-tie ")]),_c('br'),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive),expression:"isActive"}],staticClass:"float-right account-icons-check",staticStyle:{"position":"absolute","bottom":"15px","right":"20px"},attrs:{"color":"primary","large":""}},[_vm._v(" mdi-check-circle ")])],1)]),(_vm.is_hideBus)?_c('v-card',{staticClass:"card-selector text-center rounded-xl m-4",class:_vm.isActiveBusiness
          ? 'border-primary accoun account-selection'
          : 'border-light account-selection',attrs:{"rounded":"xl","elevation":"4","disabled":_vm.isActivepersonal,"tile":""},on:{"click":function($event){return _vm.onClickBusinessAccount('business')}}},[_c('div',{staticClass:"p-4 h-100",staticStyle:{"position":"relative"}},[_c('h6',{class:_vm.isActiveBusiness
              ? 'text-primary-color font-weight-bold'
              : 'text-secondary font-weight-normal'},[_vm._v(" "+_vm._s(_vm.$t("business"))+" ")]),_c('br'),_c('v-icon',{staticClass:"account-icons",staticStyle:{"width":"90px","height":"85px","color":"#b4b4b4","border-radius":"50%"},style:(_vm.isActiveBusiness
              ? 'border: 1px solid #ff6b00;'
              : 'border: 1px solid #6c757d;'),attrs:{"color":_vm.isActiveBusiness ? 'primary' : 'grey',"large":""}},[_vm._v(" mdi-briefcase-account ")]),_c('br'),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActiveBusiness),expression:"isActiveBusiness"}],staticClass:"float-right account-icons-check",staticStyle:{"position":"absolute","bottom":"15px","right":"20px"},attrs:{"color":"primary","large":""}},[_vm._v(" mdi-check-circle ")])],1)]):_vm._e()],1),_c('v-btn',{staticClass:"btn-primary-outlined text-capitalize float-right mt-5 me-3 px-5",attrs:{"outlined":""},on:{"click":_vm.onClickNext}},[_vm._v(" "+_vm._s(_vm.$t("next"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-arrow-right ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }