<template>
  <section class="w-75 m-auto account-main-div">
    <v-alert
      v-if="alertMsg.status"
      :value="alertMsg.status"
      :key="alertMsg.key"
      id="alert"
      :timeout="alertMsg.timeout"
      :color="alertMsg.color"
      :type="alertMsg.type"
      dismissible
    >
      {{ alertMsg.text }}
    </v-alert>
    <v-col v-if="isTrans == 'true'" class="d-flex justify-content-end">
      <v-btn color="primary" @click="onClickCancel">
        {{ $t("cancel_beneficary") }}
      </v-btn>
    </v-col>
    <div class="d-flex justify-content-center">
      <v-card
        elevation="4"
        rounded="xl"
        :disabled="isactiveBus"
        tile
        :class="
          isActive
            ? 'border-primary accoun account-selection'
            : 'border-light account-selection'
        "
        class="card-selector text-center rounded-xl m-4"
        @click="onClickPersonalAccount('personal')"
      >
        <div class="p-4 h-100" style="position: relative">
          <h6
            :class="
              isActive
                ? 'text-primary-color font-weight-bold'
                : 'text-secondary font-weight-normal'
            "
          >
            {{ $t("personal") }}
          </h6>
          <br />
          <v-icon
            :color="isActive ? 'primary' : 'grey'"
            large
            class="account-icons"
            :style="
              isActive
                ? 'border: 1px solid #ff6b00;'
                : 'border: 1px solid #6c757d;'
            "
          >
            mdi-account-tie
          </v-icon>
          <br />

          <v-icon
            v-show="isActive"
            color="primary"
            large
            class="float-right account-icons-check"
            style="position: absolute; bottom: 15px; right: 20px"
          >
            mdi-check-circle
          </v-icon>
        </div>
      </v-card>

      <v-card
        rounded="xl"
        elevation="4"
        :disabled="isActivepersonal"
        tile
        :class="
          isActiveBusiness
            ? 'border-primary accoun account-selection'
            : 'border-light account-selection'
        "
        class="card-selector text-center rounded-xl m-4"
        @click="onClickBusinessAccount('business')"
        v-if="is_hideBus"
      >
        <div class="p-4 h-100" style="position: relative">
          <h6
            :class="
              isActiveBusiness
                ? 'text-primary-color font-weight-bold'
                : 'text-secondary font-weight-normal'
            "
          >
            {{ $t("business") }}
          </h6>
          <br />
          <v-icon
            :color="isActiveBusiness ? 'primary' : 'grey'"
            large
            class="account-icons"
            style="
              width: 90px;
              height: 85px;
              color: #b4b4b4;
              border-radius: 50%;
            "
            :style="
              isActiveBusiness
                ? 'border: 1px solid #ff6b00;'
                : 'border: 1px solid #6c757d;'
            "
          >
            mdi-briefcase-account
          </v-icon>
          <br />

          <v-icon
            v-show="isActiveBusiness"
            color="primary"
            large
            class="float-right account-icons-check"
            style="position: absolute; bottom: 15px; right: 20px"
          >
            mdi-check-circle
          </v-icon>
        </div>
      </v-card>
    </div>
    <v-btn
      class="btn-primary-outlined text-capitalize float-right mt-5 me-3 px-5"
      @click="onClickNext"
      outlined
    >
      {{ $t("next") }}
      <v-icon dark right> mdi-arrow-right </v-icon>
    </v-btn>
  </section>
</template>

<script>
export default {
  name: "BenificiaryType",
  data() {
    return {
      name: "",
      isActive: false,
      hasError: false,
      isActiveBusiness: false,
      hasErrorBusiness: false,
      isActivepersonal: false,
      isactiveBus: false,
      is_hideBus: true,
      alertMsg: {
        status: false,
        timeout: "",
        color: "",
        type: "",
        key: Date.now(),
      },
    };
  },
  props: {
    beneficiaryReqBody: {
      type: Object,
      required: true,
    },
    isTrans: {
      type: String,
      default: "",
    },
  },
  mounted() {
    let is_partner = this.$getLocalStorage("is_partner");
    if (is_partner) {
      this.is_hideBus = false;
    }
    setTimeout(() => {
      if (
        !this.$route.query.Remittee_ID &&
        this.beneficiaryReqBody.registration_type_cd == "BUS"
      ) {
        this.isActive = false;
        this.isActiveBusiness = true;
      } else {
        this.isActive = true;
        this.isActiveBusiness = false;
      }
    }, 1000);
    if (this.$route.query.Remittee_ID) {
      if (this.beneficiaryReqBody.registration_type_cd == "BUS") {
        this.isActive = false;
        this.isActiveBusiness = true;
        this.isactiveBus = true;
      } else {
        this.isActive = true;
        this.isActiveBusiness = false;
        this.isActivepersonal = true;
      }
    }
  },
  methods: {
    onClickCancel() {
      this.$router.push("/transaction/3");
    },
    async onClickNext() {
      this.$emit("onClickNext", this.beneficiaryReqBody);
    },
    onClickPersonalAccount() {
      this.isActive = true;
      this.isActiveBusiness = false;
      Object.assign(this.beneficiaryReqBody, { registration_type_cd: "IND" });
    },

    onClickBusinessAccount() {
      this.isActive = false;
      this.isActiveBusiness = true;
      Object.assign(this.beneficiaryReqBody, { registration_type_cd: "BUS" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";
.btn-light {
  font-weight: bold;
  color: $dimgrey;
  background: $white;
  margin: 0 10px;
  &:not(:disabled) {
    &:not(.disabled).active {
      color: $secondary;
    }
    &:not(.disabled) {
      &:active {
        color: $secondary;
      }
    }
  }
  &:hover {
    // color: #fa6706;
    background: $white;
  }
}
.selected_class {
  &:hover {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    background: #f8f9fa;
    border-radius: 7px;
  }
}
button.btn.px-5.py-3.btn-light.btn-lg.individual-select {
  border: 1px solid rgb(255, 0, 43) !important;
  background: #fff;
}
button.btn.px-5.py-3.btn-light.btn-lg.business-select {
  border: 1px solid red !important;
  background: #fff;
}
.border-primary {
  border: 1px solid $primary;
}
</style>
